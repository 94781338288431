const firebaseConfig = {
    apiKey: "AIzaSyCfzk07U4_8id5FTU5LlM42o3QIG_6GBUE",
    authDomain: "bolsonaro-ipsum.firebaseapp.com",
    databaseURL: "https://bolsonaro-ipsum.firebaseio.com",
    projectId: "bolsonaro-ipsum",
    storageBucket: "",
    messagingSenderId: "606904570548",
    appId: "1:606904570548:web:dd65f8395052d127b8fde1",
    measurementId: "G-GFL0PQN4CC"
  };

  export default firebaseConfig;